/** Beta Extensions */
export const EXT_BETA_TICKET_LOCATIONS = "beta-ticketLocations";
export const EXT_BETA_POLICY = "beta-policy";
export const EXT_BETA_AVAILABILITY_PRICING_TYPE = "beta-availabilityPricingType";

/** Disney Specific Extensions */
export const EXT_DISNEY_SALES_START_DATE = "disney-salesStartDate";
export const EXT_DISNEY_SALES_END_DATE = "disney-salesEndDate";
export const EXT_DISNEY_USAGE_START_DATE = "disney-usageStartDate";
export const EXT_DISNEY_USAGE_END_DATE = "disney-usageEndDate";
export const EXT_DISNEY_TICKET_START_DATE = "disney-ticketStartDate";
export const EXT_DISNEY_TICKET_END_DATE = "disney-ticketEndDate";
export const EXT_DISNEY_PARK_LOCATIONS = "disney-parkLocations";
export const EXT_DISNEY_PARK_RESERVATIONS_REQUIRED = "disney-parkReservationRequired";
export const EXT_DISNEY_PARK_RESERVATION_DATE = "disney-parkReservationDate";
export const EXT_DISNEY_PARK_RESERVATION_STATUS = "disney-parkReservationStatus";
export const EXT_DISNEY_BULK_TICKET_PURCHASE = "disney-bulkTicketPurchase";
export const EXT_DISNEY_BULK_TICKET_DELIVERY_DATE = "disney-deliveryDate";
export const EXT_DISNEY_BULK_TICKET_DELIVERY_INSTRUCTIONS = "disney-deliveryInstructions";
export const EXT_DISNEY_BULK_TICKET_DELIVERY_TYPE = "disney-deliveryType";
export const EXT_DISNEY_DATE_BASED_TICKET = "disney-dateBasedTicket";
export const EXT_DISNEY_DISCOUNT_GROUPS = "disney-discountGroups";
export const EXT_DISNEY_MARKET_REGIONS = "disney-marketRegions";
export const EXT_DISNEY_PRODUCT_DURATION = "disney-productDuration";
export const EXT_DISNEY_BOOKING_BUFFER_DAYS = "disney-bookingBufferDays";

/** Reseller Portal Specific Extensions */
export const EXT_RESELLER_PORTAL_AGENT_NAME = "x-resellerPortal.AgentName";
export const EXT_RESELLER_PORTAL_AGENT_EMAIL = "x-resellerPortal.AgentEmail";
export const EXT_RESELLER_PORTAL_RESELLER_NAME = "x-resellerPortal.ResellerName";
export const EXT_RESELLER_PORTAL_RESELLER_REGION = "x-resellerPortal.ResellerRegion";

/** others */
export const EXT_TICKETING_SYSTEM = "x-ticketingSystem";
export const EXT_SUPPLIER_REFERENCE = "supplier.reference";
